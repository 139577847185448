import type Konva from "konva";
import type { Node as KonvaNode } from "konva/lib/Node";
import { useCounter, useRefHistory } from "@vueuse/core";
import { defineStore } from "pinia";
import { ref } from "vue";
import { getShapeConfig } from "../constants/shapes";
import type { Shape } from "@/telestrations/types/canvas";

// Store for managing shapes
export const useShapesStore = defineStore("shapes", () => {
  const toolsStore = useToolsStore();
  const canvasStore = useCanvasStore();
  const shapes = ref<Shape[]>([]);
  const selectedShape = ref<Shape | null>(null);
  const transformerRef = ref<Konva.Transformer | null>(null);
  const transformerPosition = ref<{ x: number; y: number } | null>(null);
  const isDrawing = ref(false);
  const drawingShape = ref<Shape | null>(null);
  const { count: shapeId, inc: incrementShapeId } = useCounter(1);

  const historyState = useRefHistory(shapes, {
    deep: true,
  });

  function selectShape(shape: Shape | null) {
    selectedShape.value = shape;

    attachTransformerToShape(shape);
    updateTransformerPosition();
  }

  function addShape(shape: Shape) {
    shapes.value.push(shape);
  }

  function removeShape(id: string) {
    shapes.value = shapes.value.filter(shape => shape.id !== id);

    if (selectedShape.value?.id === id) {
      selectShape(null);
    }
  }

  function clearShapes() {
    shapes.value = [];
    selectShape(null);
    toolsStore.resetToSelectionTool();
  }

  function updateShape(shapeToUpdate: Shape, updates: Partial<Shape>) {
    const shape = shapes.value.find(s => s.id === shapeToUpdate.id);

    if (shape) {
      Object.assign(shape, updates);
    }
  }

  function getShapeNode(shape: Shape) {
    const stage = canvasStore.konvaStageRef?.getStage();
    if (!stage) {
      console.error("Konva stage not found");
      return null;
    }
    const node = stage.findOne(`#${shape.id}`);
    return node;
  }

  function updateTransformerPosition() {
    if (!selectedShape.value) {
      transformerPosition.value = null;
      return;
    }
    if (!transformerRef.value) {
      transformerPosition.value = null;
      return;
    }

    const transformerNode = transformerRef.value.getNode() as Konva.Transformer;

    const box = transformerNode.getClientRect();

    transformerPosition.value = { x: box.x, y: box.y };
  }

  function reorderLayer(id: string, newIndex: number) {
    const shape = shapes.value.find(s => s.id === id);
    if (shape) {
      shape.layerIndex = newIndex;
      shapes.value.sort((a, b) => a.layerIndex - b.layerIndex);
    }
  }

  function sendShapeToTop(id: string) {
    const index = shapes.value.findIndex(s => String(s.id) === String(id));
    if (index === -1) {
      console.warn(`No shape found with provided id - '${id}'`);
      return;
    }

    const [shape] = shapes.value.splice(index, 1);
    shapes.value.push(shape); // Move to the top (last in the array)
  }

  function sendShapeToBottom(id: string) {
    const index = shapes.value.findIndex(s => String(s.id) === String(id));
    if (index === -1) {
      console.warn(`No shape found with provided id - '${id}'`);
      return;
    }

    const [shape] = shapes.value.splice(index, 1);
    shapes.value.unshift(shape); // Move to the bottom (first in the array)
  }

  function handleSelectedShapeDraw(e: Event) {
    selectShape(null);

    if (!toolsStore.selectedTool || toolsStore.selectedTool === "selection") {
      return;
    }

    const selectedShape = toolsStore.selectedTool;

    const node = e.target as KonvaNode | null;

    if (!node) {
      console.warn("No node found");
      return;
    }

    const stage = node.getStage();

    if (!stage) {
      console.warn("No stage found");
      return;
    }

    selectShape(null);

    const pos = stage.getPointerPosition();

    if (!pos) {
      console.warn("No position found");
      return;
    }

    const startX = pos.x;
    const startY = pos.y;

    const shape = getShapeConfig({
      id: String(shapeId.value),
      layerIndex: shapes.value.length,
      type: selectedShape,
      startX: startX,
      startY: startY,
    });

    addShape(shape);
    incrementShapeId();
    drawingShape.value = shape;
  }

  function onShapeDragStart(e: Event) {
    onShapeDragMove(e);
  }

  function onShapeDragMove(e: Event) {
    const node = e.target as KonvaNode | null;

    if (!node) {
      transformerPosition.value = null;
      return;
    }

    updateTransformerPosition();
  }

  function onShapeDragEnd(e: Event, shape: Shape) {
    const node = e.target as KonvaNode | null;

    if (!node) {
      console.warn("No node found");
      return;
    }

    updateShape(shape, { x: node.x(), y: node.y() });
  }

  function onShapeTransformEnd(e: Event) {
    // shape is transformed, let us save new attrs back to the node
    // find element in our state
    const shape = shapes.value.find(s => s.id === selectedShape.value?.id);

    if (!shape) {
      console.warn("No shape found");
      return;
    }

    const node = e.target as KonvaNode | null;

    if (!node) {
      console.warn("No node found");
      return;
    }

    // update the state
    updateShape(shape, {
      x: node.x(),
      y: node.y(),
      rotation: node.rotation(),
      scaleX: node.scaleX(),
      scaleY: node.scaleY(),
    });
  }

  function attachTransformerToShape(shape: Shape | null) {
    if (!transformerRef.value) {
      console.warn("No transformer found");
      return;
    }

    const transformerNode = transformerRef.value.getNode() as Konva.Transformer;
    const stage = transformerNode.getStage();

    if (!stage) {
      console.warn("No stage found");
      return;
    }

    const selectedNode = stage.findOne(`#${shape?.id}`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error ....
    // do nothing if selected node is already attached
    if (selectedNode === transformerNode.node()) {
      // console.warn("Transformer already attached");
      return;
    }

    if (selectedNode) {
      // attach to another node
      transformerNode.nodes([selectedNode]);
    }
    else {
      // remove transformer
      transformerNode.nodes([]);
    }
  }

  const $reset = () => {
    shapes.value = [];
    selectedShape.value = null;
    transformerRef.value = null;
    transformerPosition.value = null;
    isDrawing.value = false;
    drawingShape.value = null;
  };

  return {
    $reset,
    shapes,
    selectedShape,
    isDrawing,
    drawingShape,
    transformerRef,
    transformerPosition,
    sendShapeToTop,
    sendShapeToBottom,
    selectShape,
    addShape,
    removeShape,
    clearShapes,
    updateShape,
    updateTransformerPosition,
    getShapeNode,
    historyState,
    reorderLayer,
    onShapeDragStart,
    onShapeDragMove,
    onShapeDragEnd,
    onShapeTransformEnd,
    handleSelectedShapeDraw,
    attachTransformerToShape,
  };
});
