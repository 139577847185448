<script lang="ts" setup>
const { t } = useI18n();
const toast = useToast();
const telestrationStore = useTelestrationStore();

const { isLoading: isDisabled, execute: onSave } = useAsyncState(
  () => {
    if (
      !telestrationStore.playlistId
      || !telestrationStore.activePlaylistItem
    ) {
      toast.error("No playlist ID or active playlist item");
      return Promise.resolve();
    }

    return telestrationStore.saveTelestration();
  },
  null,
  {
    immediate: false,
    onSuccess: () => {
      toast.success(t("pages.playlists.modal.playlist_save_success"));
      telestrationStore.exitTelestrationMode();
    },
    onError: err => toast.error(parseErrorMessage(err, { t })),
  },
);
</script>

<template>
  <TButton
    variant="primary"
    :label="t('labels.save')"
    :disabled="isDisabled"
    @click="onSave"
  />
</template>
