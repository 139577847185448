import payload_plugin_VvvAkBZ_VOuFjWj7DBj0dOJXfe4ab4QfX6erGHItx8I from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.36.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_GdbZdMoVXlP_gzbyYp7pu2R664_VR1h77te7m1okioY from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._be40bdf65a90f76a5b1c64d7cb773ecd/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_R5WZlwcKVp39XahJwWMG_B_E2txQgNemO8kiJXOcvQw from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._be40bdf65a90f76a5b1c64d7cb773ecd/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3fDblx4vvWpFGPDelbfmHtp6JBWnuLi9vEss93vjWaE from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._be40bdf65a90f76a5b1c64d7cb773ecd/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_3dnzXZL6OQRNMO8hsAswvjz_sgCAj6ZA8LlO5JrOdp0 from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._be40bdf65a90f76a5b1c64d7cb773ecd/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_EJdlukM0dmYmPVVA7SDOHH9TLn4B1UGKU_UK91uS1Qo from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._be40bdf65a90f76a5b1c64d7cb773ecd/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_CLIImi7oOOYMiT_LU7P5ox52E_q2UKHZlcXuviMiPK4 from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._be40bdf65a90f76a5b1c64d7cb773ecd/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Gqpp9BIvs3AFdddxYKM2RszBids7A5VOABx0sKiYY68 from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.36.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_4pImOBrpDGm3CpZSnG5Yx8emblvZpyGrrzTPofrDQ9c from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.3.1_eslint@9.22.0_jiti@2.4._be40bdf65a90f76a5b1c64d7cb773ecd/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KxFiT_EOJpsW1cU_3BKhMRHQ66kRM_gtELZHkbJ2nug from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.36.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_Q82PGvcygJOrecCkBGfEXKuR1sIETo475cqmlX5Z_8M from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_884dacd093a5328c1ae5a894922f2f65/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_6NWWSCyrTVD3_hClx37AwZwubLcG2gSbv8WCKgJFWfo from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_884dacd093a5328c1ae5a894922f2f65/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_5miEiQHqM_U1VL1e3MaRPN1BQ3MBck1pRFeoyeSTFAw from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_884dacd093a5328c1ae5a894922f2f65/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_iIjw6U4054CXPakJrlOMbwLDA_RAtC1Y_8TjnLqVuwA from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-zod-i18n@1.11.5_magicast@0.3.5_rollup@4.36.0_typescript@5.7.2/node_modules/nuxt-zod-i18n/dist/runtime/plugin.js";
import plugin_client_KoYRQtJuzxoXnHGQ2woLdhTP7GR5uYvaiqLq7_TKqnU from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.36.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_KnPiXuwPJd3CJQ9W9Evay9oeP4gCOl_M_b4o7vcONFQ from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/nuxt-open-fetch@0.2.2_magicast@0.3.5_rollup@4.36.0/node_modules/nuxt-open-fetch/dist/runtime/plugin.mjs";
import konva_client_4Yp6TgmVvp29e9WG4hyScSYiClskEdtETSDNekjK0b0 from "/home/runner/work/ronaldo-web/ronaldo-web/app/telestrations/plugins/konva.client.ts";
import firebase_client_fBeI1p_eszNDPpTz2RvzN0iRzs05mJgyUbXp5CNK8Rg from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/plugins/firebase.client.ts";
import middleware_global_f3_wp13giM1lz7bWtyHFQAK7xZ6GrtZJkX9OcuMpvQ4 from "/home/runner/work/ronaldo-web/ronaldo-web/app/auth/plugins/middleware.global.ts";
import auto_animate_client_OoX1bbRFfGzoplLwq9yT7s4PQ6UCaXjUr280xijO7D4 from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/auto-animate.client.ts";
import datadog_client_eccJXs_ApWON3IwA4lxNFwcNrr0pG0paiYoBsYtUrVI from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/datadog.client.ts";
import floating_vue__wekiCMwPmPywGdgKsXmMB_xM8ewX1Swnhf9eCglgUU from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/floating-vue.ts";
import google_analytics_client_GrBxW6ap1cdqBVxvODouTelKU7C1c4uQur_9TBhPCYA from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/google-analytics.client.ts";
import modals_gID_HIHj50tK5KHAANS9atOYDti1KilEDW5PuzOUxDE from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/modals.ts";
import openFetch_ywB5hZimVwXHfNga77Cs8PGTJea3Ozkp5Cj2MZah_ec from "/home/runner/work/ronaldo-web/ronaldo-web/app/common/plugins/openFetch.ts";
import ssg_detect_CDjZhgDeo1OTnVkIraKbqM_3njMWChhWNGVQ_Sw8WTo from "/home/runner/work/ronaldo-web/ronaldo-web/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_884dacd093a5328c1ae5a894922f2f65/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_VvvAkBZ_VOuFjWj7DBj0dOJXfe4ab4QfX6erGHItx8I,
  revive_payload_client_GdbZdMoVXlP_gzbyYp7pu2R664_VR1h77te7m1okioY,
  unhead_R5WZlwcKVp39XahJwWMG_B_E2txQgNemO8kiJXOcvQw,
  router_3fDblx4vvWpFGPDelbfmHtp6JBWnuLi9vEss93vjWaE,
  payload_client_3dnzXZL6OQRNMO8hsAswvjz_sgCAj6ZA8LlO5JrOdp0,
  navigation_repaint_client_EJdlukM0dmYmPVVA7SDOHH9TLn4B1UGKU_UK91uS1Qo,
  chunk_reload_client_CLIImi7oOOYMiT_LU7P5ox52E_q2UKHZlcXuviMiPK4,
  plugin_vue3_Gqpp9BIvs3AFdddxYKM2RszBids7A5VOABx0sKiYY68,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_4pImOBrpDGm3CpZSnG5Yx8emblvZpyGrrzTPofrDQ9c,
  plugin_KxFiT_EOJpsW1cU_3BKhMRHQ66kRM_gtELZHkbJ2nug,
  switch_locale_path_ssr_Q82PGvcygJOrecCkBGfEXKuR1sIETo475cqmlX5Z_8M,
  route_locale_detect_6NWWSCyrTVD3_hClx37AwZwubLcG2gSbv8WCKgJFWfo,
  i18n_5miEiQHqM_U1VL1e3MaRPN1BQ3MBck1pRFeoyeSTFAw,
  plugin_iIjw6U4054CXPakJrlOMbwLDA_RAtC1Y_8TjnLqVuwA,
  plugin_client_KoYRQtJuzxoXnHGQ2woLdhTP7GR5uYvaiqLq7_TKqnU,
  plugin_KnPiXuwPJd3CJQ9W9Evay9oeP4gCOl_M_b4o7vcONFQ,
  konva_client_4Yp6TgmVvp29e9WG4hyScSYiClskEdtETSDNekjK0b0,
  firebase_client_fBeI1p_eszNDPpTz2RvzN0iRzs05mJgyUbXp5CNK8Rg,
  middleware_global_f3_wp13giM1lz7bWtyHFQAK7xZ6GrtZJkX9OcuMpvQ4,
  auto_animate_client_OoX1bbRFfGzoplLwq9yT7s4PQ6UCaXjUr280xijO7D4,
  datadog_client_eccJXs_ApWON3IwA4lxNFwcNrr0pG0paiYoBsYtUrVI,
  floating_vue__wekiCMwPmPywGdgKsXmMB_xM8ewX1Swnhf9eCglgUU,
  google_analytics_client_GrBxW6ap1cdqBVxvODouTelKU7C1c4uQur_9TBhPCYA,
  modals_gID_HIHj50tK5KHAANS9atOYDti1KilEDW5PuzOUxDE,
  openFetch_ywB5hZimVwXHfNga77Cs8PGTJea3Ozkp5Cj2MZah_ec,
  ssg_detect_CDjZhgDeo1OTnVkIraKbqM_3njMWChhWNGVQ_Sw8WTo
]