import getSharedHeaders from "~/common/helpers/get-headers";
import type { IResponseTelestration, ITelestration } from "~/types";
import { formatTelestration } from "~/videos/helpers/format-playlist";

export function getStage(id: string, targetId: string, options?: { accessToken?: string; locale?: string }): Promise<any> {
  return useAPI<{ data: any }>(`api/v1/playlists/${id}/${targetId}/telestrations`, {
    headers: getSharedHeaders(options),
  }).then(response => response.data);
}

export function saveStage(id: string, targetId: string, data: {
  start: number;
  body: string;
}): Promise<ITelestration> {
  return useAPI<{ data: any }>(`api/v1/playlists/${id}/${targetId}/telestrations`, {
    headers: getSharedHeaders({ locale: "en" }),
    method: "POST",
    body: data,
  }).then((telestration: { data: IResponseTelestration }) => formatTelestration(telestration.data));
}

export function patchStage(
  id: string,
  targetId: string,
  telestrationId: string,
  data: {
    start: number;
    body: string;
  },
): Promise<ITelestration> {
  return useAPI<{ data: any }>(`api/v1/playlists/${id}/${targetId}/telestrations/${telestrationId}`, {
    headers: getSharedHeaders({ locale: "en" }),
    method: "PATCH",
    body: data,
  }).then((telestration: { data: IResponseTelestration }) => formatTelestration(telestration.data));
}

export function deleteStage(
  id: string,
  targetId: string,
  telestrationId: string,
): Promise<any> {
  return useAPI(`api/v1/playlists/${id}/${targetId}/telestrations/${telestrationId}`, {
    headers: getSharedHeaders({ locale: "en" }),
    method: "DELETE",
  }).then(() => console.log("Deleted"));
}
