import type Konva from "konva";
import { useElementSize, useFullscreen } from "@vueuse/core";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useCanvasStore = defineStore("canvas", () => {
  const containerRef = ref<HTMLDivElement | null>(null);
  const konvaStageRef = ref<Konva.Stage | null>(null);
  const baseWidth = 800;
  const baseHeight = 600;

  const containerSize = useElementSize(containerRef, { width: baseWidth, height: baseHeight });
  const telestrationButtonShown = ref<boolean>(isFeatureEnabled("enable_telestration_mode"));
  const telestrationModeEnabled = ref<boolean>(false);
  const canvasSize = ref({
    // TODO: We can remove .value and it will become reactive and sync the container size
    // However it has some issues with the fullscreen and it very depends on the container element
    // Sometimes we can end up in the infinite loop. So we need to find a better solution
    width: containerSize.width,
    height: containerSize.height,
  });
  const scaleFactor = computed(() => {
    const width = containerSize.width.value;
    const height = (width / baseWidth) * baseHeight; // Maintain aspect ratio

    const scaleX = width / baseWidth;
    const scaleY = height / baseHeight;

    return {
      width,
      height,
      scaleX,
      scaleY,
    };
  });
  // const scaleFactor = computed(() => Math.min(
  //   canvasSize.value.width / baseWidth,
  //   canvasSize.value.height / baseHeight,
  // ));
  const fullscreenState = useFullscreen(containerRef);

  const stageConfig = computed<Konva.StageConfig>(() => ({
    width: canvasSize.value.width,
    height: canvasSize.value.height,
    // scaleX: scaleFactor.value.scaleX,
    // scaleY: scaleFactor.value.scaleY,
    scaleX: 1,
    scaleY: 1,
  }));

  return {
    stageConfig,
    containerRef,
    konvaStageRef,
    canvasSize,
    telestrationButtonShown,
    telestrationModeEnabled,
    scaleFactor,
    fullscreenState,
  };
});
