import { defineStore } from "pinia";
import { convertKonvaStageToSvg, encodeSvgStringBase64 } from "~/telestrations/helpers/stageToSvg";
import { addSvgElementsToLayer, decodeSvgDataUrl } from "~/telestrations/helpers/svgToStage";
import type { IPlaylistItem } from "~/types";

export const useTelestrationStore = defineStore("telestration", () => {
  const activePlaylistItem = ref<IPlaylistItem | null>(null);
  const playlistId = ref<string>("");
  const telestrationStart = ref<number>(0);
  const telestrationDeleted = ref<boolean>(false);

  function deleteTelestration(): Promise<any> {
    const existingTagTimeTelestration = activePlaylistItem.value?.telestrations.find(t => Math.abs(telestrationStart.value - (t.start || 0)) <= 1);
    if (existingTagTimeTelestration && activePlaylistItem.value) {
      return deleteStage(playlistId.value, activePlaylistItem.value.id || "", existingTagTimeTelestration.id).then(() => {
        telestrationDeleted.value = true;
        activePlaylistItem.value = {
          ...activePlaylistItem.value,
          telestrations: activePlaylistItem.value?.telestrations.filter(t => t.id !== existingTagTimeTelestration.id) || [],
        } as IPlaylistItem;
      });
    }
    return Promise.resolve();
  }

  function saveTelestration(): Promise<any> {
    const existingTagTimeTelestration = activePlaylistItem.value?.telestrations.find(t => Math.abs(telestrationStart.value - (t.start || 0)) <= 1);
    const svg = convertKonvaStageToSvg();
    if (!svg) {
      return deleteTelestration();
    }
    const svgEncoded = encodeSvgStringBase64(svg);
    if (existingTagTimeTelestration && !telestrationDeleted.value) {
      return patchStage(
        playlistId.value,
        activePlaylistItem.value?.id || "",
        existingTagTimeTelestration.id,
        {
          start: telestrationStart.value,
          body: svgEncoded,
        },
      ).then(() => {
        telestrationDeleted.value = false;
      });
    }
    else {
      return saveStage(
        playlistId.value,
        activePlaylistItem.value?.id || "",
        {
          start: telestrationStart.value,
          body: svgEncoded,
        },
      ).then((res) => {
        activePlaylistItem.value = {
          ...activePlaylistItem.value,
          telestrations: [...(activePlaylistItem.value?.telestrations || []), res],
        } as IPlaylistItem;
        telestrationDeleted.value = false;
      });
    }
  }

  function enterTelestrationMode(item: IPlaylistItem): void {
    if (item) {
      const currentTelestrations = (item.telestrations || []).filter(({ start }) => {
        return Math.abs(telestrationStart.value - (start || 0)) <= 1 && start;
      });

      currentTelestrations.forEach((t) => {
        const decodedSvg = decodeSvgDataUrl(t?.body || "") || "";
        addSvgElementsToLayer(decodedSvg);
      });
    }
  }

  function exitTelestrationMode(): void {
    useShapesStore().$reset();
    useCanvasStore().telestrationModeEnabled = false;
  }

  return {
    exitTelestrationMode,
    enterTelestrationMode,
    saveTelestration,
    deleteTelestration,
    telestrationStart,
    playlistId,
    activePlaylistItem,
  };
});
