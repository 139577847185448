<script lang="ts" setup>
import type { ShallowRef } from "vue";
import { twJoin } from "tailwind-merge";
import type { RouteLocationRaw } from "#vue-router";
import type { PxlIcon } from "@/common/components/U/Icon";
import type { MenuState } from "@/common/components/U/Menu/Menu.vue";
import type { UAvatar } from "#components";
import { NuxtLink, UDivider } from "#components";
import { iconBind } from "@/common/components/U/Icon";

type UAvatarProps = InstanceType<typeof UAvatar>["$props"];

const props = withDefaults(
  defineProps<{
    to?: RouteLocationRaw;
    href?: string;
    text?: string;
    icon?: PxlIcon;
    leadingIcon?: PxlIcon;
    trailingIcon?: PxlIcon;
    tooltip?: string;
    active?: boolean;
    disabled?: boolean;
    size?: "sm" | "md" | "lg";
    onClick?: (() => void) | (() => Promise<void>);
    onClickCloseMenu?: boolean;
    // divider?: InstanceType<typeof UDivider>["$props"];
    divider?: boolean;
    hasHover?: boolean;
    image?: UAvatarProps;
    containerClass?: string;
  }>(),
  {
    size: "sm",
    hasHover: true,
    onClickCloseMenu: true,
  },
);
const emit = defineEmits(["click"]);
const [isDisabled, toggleDisabled] = useToggle(props.disabled);
const menu = inject<ShallowRef<MenuState>>("ui.menu");
const component = computed(() => {
  if (props.to) return NuxtLink;
  if (props.href) return "a";

  return "li";
});
const tabIndex = computed(() => {
  if (component.value === "li" && !props.disabled && props.hasHover)
    return "0";
  return undefined;
});
const additionalProps = computed(() => {
  if (props.to) return { to: props.to };
  if (props.href) return { href: props.href, target: "_blank", rel: "noopener noreferrer" };

  return {};
});
const additionalClass = computed(() => {
  const size = `u-list-item--${props.size}`;
  const active = "u-list-item--active";
  const disabled = "u-list-item--disabled";
  const hover = "u-list-item--hover";

  return twJoin(size, props.active && active, isDisabled.value ? disabled : props.hasHover ? hover : null);
});
async function onItemClick(event: PointerEvent) {
  if (isDisabled.value === true) return;

  if (props.onClick) {
    toggleDisabled(true);
    await props.onClick();
    toggleDisabled(false);
  }
  else {
    emit("click", event);
  }

  // Close parent menu after action click finished
  if (menu?.value.close && props.onClickCloseMenu) {
    menu?.value.close();
  }
}
</script>

<template>
  <Component
    :is="component"
    class="u-list-item"
    :class="additionalClass"
    v-bind="additionalProps"
    :tabindex="tabIndex"
    @click="onItemClick"
    @keyup.enter="onItemClick"
  >
    <div class="flex flex-1 items-center gap-3" :class="containerClass">
      <slot name="leading">
        <UIcon
          v-if="props.leadingIcon || props.icon"
          v-bind="iconBind(props.leadingIcon || props.icon, { disabled: isDisabled })"
        />
      </slot>

      <slot name="image">
        <UAvatar v-if="props.image" v-bind="props.image" :size="20" />
      </slot>

      <slot>{{ props.text }}</slot>

      <div
        v-if="props.divider"
        class="absolute -bottom-px left-0 w-full px-4"
      >
        <UDivider lighter />
      </div>
    </div>

    <slot name="trailing">
      <UIcon
        v-if="props.tooltip"
        v-tooltip="props.tooltip"
        name="info"
      />
      <UIcon
        v-if="props.trailingIcon"
        v-bind="iconBind(props.trailingIcon, { disabled: isDisabled })"
      />
    </slot>
  </Component>
</template>

<style lang="scss">
.u-list-item {
  @apply relative w-full
  flex justify-between items-center gap-4
  px-4 py-2
  font-medium text-sm
  text-black dark:text-white
  transition-colors duration-100;
  &.u-list-item--sm {
    @apply min-h-[35px];
  }
  &.u-list-item--md {
    @apply min-h-11;
  }
  &.u-list-item--lg {
    @apply min-h-[51px];
  }
  &.u-list-item--disabled {
    @apply cursor-not-allowed text-neutral-light-700 dark:text-neutral-dark-400;
  }

  &.u-list-item--active {
    @apply dark:bg-neutral-dark-500 bg-neutral-light-100;
  }
  &.u-list-item--hover {
    @apply hover:dark:bg-neutral-dark-500 hover:bg-neutral-light-100 cursor-pointer;
  }
}
</style>
