<script setup lang="ts">
import { ShapeFillColors } from "~/telestrations/constants/colors";

const { t } = useI18n();
const shapesStore = useShapesStore();

const options = computed(() => {
  if (shapesStore.selectedShape) {
    return ShapeFillColors[shapesStore.selectedShape.type];
  }

  return [];
});
const selectedFillColor = computed(() => {
  if (shapesStore.selectedShape) {
    return shapesStore.selectedShape.fill as string;
  }

  return "transparent";
});

function onShapeFillChange(value: string) {
  if (!shapesStore.selectedShape) {
    return;
  }

  shapesStore.updateShape(shapesStore.selectedShape, { fill: value });
}
</script>

<template>
  <template v-if="options.length">
    <USelectMenu
      :model-value="selectedFillColor"
      :options="options"
      input-class="!border-none !gap-x-2 pl-1"
      list-class="grid grid-cols-2 p-2 gap-x-1 gap-y-2"
      item-class="!p-0 !min-h-max rounded"
      container-class="p-1"
      variant="inline"
      :divider="false"
      :hide-trailing-icon="true"
      @update:model-value="onShapeFillChange"
    >
      <template #option="{ option }">
        <div
          v-if="option.value !== 'transparent'"
          class="size-5 rounded-full"
          :style="{ backgroundColor: option.value }"
        />
        <UIcon v-else class="size-5 rounded-full" name="no-shape-fill" />
      </template>
      <div
        v-if="selectedFillColor !== 'transparent'"
        v-tooltip="t('labels.change_fill_color')"
        class="size-5 rounded-full"
        :style="{ backgroundColor: selectedFillColor }"
      />
      <UIcon
        v-else
        v-tooltip="t('labels.change_fill_color')"
        class="size-5 rounded-full"
        name="no-shape-fill"
      />
    </USelectMenu>
  </template>
</template>
