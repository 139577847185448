<script lang="ts" setup>
import type { Node as KonvaNode } from "konva/lib/Node";
import type Konva from "konva";
import { nextTick } from "vue";
import { ShapeType } from "@/telestrations/types/canvas";

const toolsStore = useToolsStore();
const canvasStore = useCanvasStore();
const shapesStore = useShapesStore();
const telestrationStore = useTelestrationStore();

const konvaStageRef = ref<Konva.Stage | null>(null);

onMounted(() => {
  canvasStore.konvaStageRef = konvaStageRef.value;
  if (telestrationStore.activePlaylistItem) {
    shapesStore.historyState.pause();
    telestrationStore.enterTelestrationMode(telestrationStore.activePlaylistItem);
    setTimeout(() => {
      shapesStore.historyState.resume();
    }, 100);
  }
});

async function handleStageMouseDown(e: Event) {
  const node = e.target as KonvaNode | null;

  if (!node) {
    console.warn("No node found during stage mousedown");
    return;
  }

  const stage = node.getStage();

  if (!stage) {
    console.warn("No Konva stage found");
    return;
  }
  const pos = stage.getPointerPosition();

  if (!pos) {
    console.warn("No pointer position found");
    return;
  }

  const clickedOnEmpty = node === stage;

  // clicked on stage - clear selection
  if (clickedOnEmpty) {
    shapesStore.handleSelectedShapeDraw(e);

    // wait for the next tick to update the UI
    // in that case the added shape will be drawn
    // and we will be able to select it via transformer
    await nextTick();

    shapesStore.isDrawing = true;

    return;
  }

  // clicked on transformer - do nothing
  const clickedOnTransformer
    = node.getParent()?.className === "Transformer";

  if (clickedOnTransformer) {
    return;
  }

  const shape = shapesStore.shapes.find(s => s.id === node.id());
  shapesStore.selectShape(shape ?? null);
}

function handleStageMouseUp() {
  shapesStore.isDrawing = false;

  if (shapesStore.drawingShape) {
    shapesStore.selectShape(shapesStore.drawingShape);
    shapesStore.drawingShape = null;
  }
}

async function handleStageMouseMove(e: Konva.KonvaEventObject<MouseEvent>) {
  if (!shapesStore.isDrawing) {
    return;
  }

  if (!shapesStore.drawingShape) {
    return;
  }

  const shape = shapesStore.drawingShape;

  // prevent scrolling on touch devices
  e.evt.preventDefault();

  const pos = e.target.getStage()?.getPointerPosition();

  if (!pos) {
    console.warn("No pointer position found");
    return;
  }

  const startY = shape.startY;
  const startX = shape.startX;

  switch (shape.type) {
    case ShapeType.Rectangle: {
      const width = pos.x - startX;
      const height = pos.y - startY;

      shapesStore.updateShape(shape, {
        width: (Math.abs(width)),
        height: (Math.abs(height)),
        x: (width < 0 ? startX + width : startX),
        y: (height < 0 ? startY + height : startY),
      });
      break;
    }
    case ShapeType.Circle: {
      shapesStore.updateShape(shape, {
        radius: Math.sqrt((pos.x - startX) ** 2 + (pos.y - startY) ** 2),
      });
      break;
    }
    case ShapeType.Arrow: {
      shapesStore.updateShape(shape, {
        points: [startX, startY, pos.x, pos.y],
      });
      break;
    }
    case ShapeType.Line: {
      const node = shapesStore.getShapeNode(shape) as Konva.Line | null;

      if (!node) {
        console.warn("No node found");
        return;
      }

      shapesStore.updateShape(shape, {
        points: [...node.points(), pos.x, pos.y],
      });
      break;
    }
  }

  await nextTick();

  shapesStore.updateTransformerPosition();
}
</script>

<template>
  <div class="min-h-196 relative w-full">
    <div
      class="inline-block"
      :class="{
        'cursor-crosshair': !toolsStore.isSelectionMode,
      }"
    >
      <v-stage
        ref="konvaStageRef"
        :config="canvasStore.stageConfig"
        @mousedown="handleStageMouseDown"
        @touchstart="handleStageMouseDown"
        @mousemove="handleStageMouseMove"
        @touchmove="handleStageMouseMove"
        @mouseup="handleStageMouseUp"
        @touchend="handleStageMouseUp"
      >
        <TShapesLayer />
      </v-stage>
    </div>
  </div>
</template>
