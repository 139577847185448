import Konva from "konva";
import { getShapeConfig } from "~/telestrations/constants/shapes";
import { ShapeType, type Shape } from "~/telestrations/types/canvas";

interface ISVGTransform { translateX: number; translateY: number; rotate: number; scaleX: number; scaleY: number };

export function addSvgElementsToLayer(svgString: string): void {
  const shapesStore = useShapesStore();
  const toolsStore = useToolsStore();
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
  const svgElements = svgDoc.querySelectorAll("circle, rect, text, polyline, polygon, line, path");
  svgElements.forEach((svgElement) => {
    if (svgElement !== null) {
      const tagName = svgElement.tagName.toLowerCase();
      const id = String(Math.random());
      const layerIndex = shapesStore.shapes.length;
      const [startX, startY, { translateX, translateY, scaleX, scaleY, rotate }] = [
        parseFloat(svgElement.getAttribute("startX")!),
        parseFloat(svgElement.getAttribute("startY")!),
        parseSvgTransform(svgElement.getAttribute("transform")),
      ];
      const [fill, rotation, stroke, strokeWidth, dash] = [
        svgElement.getAttribute("fill")! || Konva.Util.getRandomColor(),
        svgElement.getAttribute("rotation") ? parseFloat(svgElement.getAttribute("rotation")!) : 0,
        svgElement.getAttribute("stroke")! || "black",
        parseFloat(svgElement.getAttribute("stroke-width")!) || 1,
        svgElement.getAttribute("stroke-dasharray") || ",",
      ];

      switch (tagName) {
        case "circle":
          shapesStore.addShape(getShapeConfig({
            id,
            type: ShapeType.Circle,
            startX,
            startY,
            layerIndex,
          }, {
            strokeWidth,
            stroke,
            x: parseFloat(svgElement.getAttribute("cx")!),
            y: parseFloat(svgElement.getAttribute("cy")!),
            radius: parseFloat(svgElement.getAttribute("r")!),
            fill,
            scaleX: scaleX || 1,
            scaleY: scaleY || 1,
            dash: dash.split(",").map(d => parseFloat(d) || 0),
          }));
          break;
        case "rect":
          shapesStore.addShape(getShapeConfig({
            id,
            type: ShapeType.Rectangle,
            startX,
            startY,
            layerIndex,
          }, {
            width: parseFloat(svgElement.getAttribute("width")!),
            height: parseFloat(svgElement.getAttribute("height")!),
            strokeWidth,
            stroke,
            x: parseFloat(svgElement.getAttribute("x")!),
            y: parseFloat(svgElement.getAttribute("y")!),
            fill,
            rotation: rotation || rotate || 0,
            scaleX: scaleX || 1,
            scaleY: scaleY || 1,
            dash: dash.split(",").map(d => parseFloat(d) || 0),
          }));
          break;
        case "text":
          shapesStore.addShape(getShapeConfig({
            id,
            type: ShapeType.Text,
            startX,
            startY,
            layerIndex,
          }, {
            strokeWidth,
            stroke,
            x: parseFloat(svgElement.getAttribute("x")!),
            y: parseFloat(svgElement.getAttribute("y")!),
            fill,
            text: svgElement.textContent,
            fontSize: svgElement.getAttribute("font-size") || 12,
            fontFamily: svgElement.getAttribute("font-family") || "Arial",
            align: svgElement.getAttribute("text-anchor") || "start",
            rotation: rotation || rotate || 0,
            scaleX: scaleX || 1,
            scaleY: scaleY || 1,
          }));
          break;
        case "polyline":
        case "polygon":
          shapesStore.addShape(
            addLine(svgElement, layerIndex, startX, startY, strokeWidth, stroke, { translateX, translateY, scaleX, scaleY, rotate }),
          );
          break;
        case "path":
          shapesStore.addShape(
            getShapeConfig({
              id,
              layerIndex,
              type: ShapeType.Arrow,
              startX,
              startY,
            },
            {
              points: (svgElement.getAttribute("d")!.split(/[ML,]/).filter(Boolean).map(parseFloat) || []),
              x: translateX,
              y: translateY,
              scale: { x: scaleX, y: scaleY },
              rotation: rotation || 0,
              stroke,
              dash: dash.split(",").map(d => parseFloat(d) || 0),
            }),
          );
          break;
        default:
          console.warn(`Unsupported SVG element: ${tagName}`);
      }
      toolsStore.resetToSelectionTool();
    }
  });
}

function parseSvgTransform(transformString: string | null): ISVGTransform {
  const transformations: ISVGTransform = {} as ISVGTransform;

  if (!transformString) {
    return transformations;
  }

  // Extract translate
  const translateMatch = transformString.match(/translate\((-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)\)/);
  if (translateMatch) {
    transformations.translateX = parseFloat(translateMatch[1]);
    transformations.translateY = parseFloat(translateMatch[3]);
  }

  // Extract rotate
  const rotateMatch = transformString.match(/rotate\((-?\d+(\.\d+)?)\)/);
  if (rotateMatch) {
    transformations.rotate = parseFloat(rotateMatch[1]);
  }

  // Extract scale
  const scaleMatch = transformString.match(/scale\((-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)\)/);
  if (scaleMatch) {
    transformations.scaleX = parseFloat(scaleMatch[1]);
    transformations.scaleY = parseFloat(scaleMatch[3]);
  }
  else {
    const singleScaleMatch = transformString.match(/scale\((-?\d+(\.\d+)?)\)/);
    if (singleScaleMatch) {
      transformations.scaleX = parseFloat(singleScaleMatch[1]);
      transformations.scaleY = parseFloat(singleScaleMatch[1]);
    }
  }

  return transformations;
}

function addLine(
  svgElement: Element, layerIndex: number, startX: number, startY: number, strokeWidth: number, stroke: string,
  { translateX, translateY, scaleX, scaleY, rotate }: ISVGTransform,
): Shape {
  const pointsString = svgElement.getAttribute("points")!;
  const points = pointsString.split(" ").map((point: any) => {
    const coords = point.split(",");
    return [parseFloat(coords[0]), parseFloat(coords[1])];
  }).flat();
  const shape = getShapeConfig({
    id: svgElement.getAttribute("id")!,
    layerIndex,
    type: ShapeType.Line,
    startX,
    startY,
  }, {
    x: translateX,
    y: translateY,
    points,
    scale: { x: scaleX || 1, y: scaleY || 1 },
    rotation: rotate || 0,
    strokeWidth,
    stroke,
  });
  return shape;
}

export function decodeSvgDataUrl(dataUrl: string): string | null {
  if (!dataUrl.startsWith("data:image/svg+xml")) {
    return null; // Not an SVG data URL
  }
  const parts = dataUrl.split(",");
  if (parts.length < 2) {
    return null;
  }
  const encoding = parts[0];
  const encodedData = parts.slice(1).join(",");
  if (encoding.includes("base64")) {
    try {
      return atob(encodedData);
    }
    catch (error) {
      console.error("Error decoding base64:", error);
      return null;
    }
  }
  else {
    try {
      let decoded = decodeURIComponent(encodedData);
      decoded = decoded.replace(/'/g, "\""); // Restore double quotes.
      return decoded;
    }
    catch (error) {
      console.error("Error decoding URL:", error);
      return null;
    }
  }
}
