<script lang="ts" setup>
import { ShapeType } from "@/telestrations/types/canvas";

const shapesStore = useShapesStore();
const transformerRef = ref();

onMounted(() => {
  shapesStore.transformerRef = transformerRef.value;
});

function onTransformEvent() {
  shapesStore.updateTransformerPosition();
}
</script>

<template>
  <v-layer>
    <template v-for="shape in shapesStore.shapes" :key="shape.id">
      <TShapeCircle v-if="shape.type === ShapeType.Circle" :shape="shape" />
      <TShapeArrow v-if="shape.type === ShapeType.Arrow" :shape="shape" />
      <TShapeLine v-if="shape.type === ShapeType.Line" :shape="shape" />
      <TShapeRect v-if="shape.type === ShapeType.Rectangle" :shape="shape" />
      <TShapeText v-if="shape.type === ShapeType.Text" :shape="shape" />
    </template>
    <v-transformer
      ref="transformerRef"
      @dragstart="onTransformEvent"
      @dragmove="onTransformEvent"
      @dragend="onTransformEvent"
      @click="onTransformEvent"
      @transformstart="onTransformEvent"
      @transform="onTransformEvent"
      @transformend="onTransformEvent"
    />
  </v-layer>
</template>
