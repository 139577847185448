<script setup lang="ts">
import type { VideoJsPlayer as IPixellotPlayer, IPlayerControlLink, ITag as IPixellotTag, ITagGroup as IPixellotTagGroup } from "@pixellot/web-sdk";
import { clamp, useMagicKeys, breakpointsTailwind } from "@vueuse/core";
import { TagRepresentation } from "@pixellot/videojs-base/src/Plugins/Tags/index";
import type { IPlayerData } from "./Player.vue";
import type { IPixellotPlayerVideoClipOptions, IPlayerState } from "~~/modules/pixellot-sdk";
import type { IBasicVideo, IEvent, IPlaylistItem, IPlaylist, IClip } from "@/types";
import type { VideoPlayer } from "#components";
import { VIDEO_TYPE, SOURCE_TYPE } from "@/constants";
import TelestrationSidePanel from "~/videos/components/Video/TelestrationSidePanel.vue";

const { t, locale } = useI18n();
const toast = useToast();
const route = useRoute();
const router = useRouter();
const segment = useSegment();
const canvasStore = useCanvasStore();
const telestrationStore = useTelestrationStore();
const isOpen = ref(false);
const [isInPiPMode, togglePiPMode] = useToggle(false);
const isInNativePiPMode = ref<boolean>(false);
const video = ref<IEvent | IBasicVideo | null>(null);
const playlist = ref<IPlaylist | null>(null);
const breakpoints = useBreakpoints(breakpointsTailwind);
const greaterOrEqualLg = breakpoints.greaterOrEqual("lg");
const videoPlayerRef = ref<InstanceType<typeof VideoPlayer> | null>(null);
const playerInstance = computed<IPixellotPlayer | null>(() => videoPlayerRef.value ? videoPlayerRef.value.player : null);
const playerError = computed<boolean>(() => (videoPlayerRef.value ? videoPlayerRef.value.error : false));
const playerState = computed<IPlayerState | null>(() => (videoPlayerRef.value ? videoPlayerRef.value.state : null));
const modalRootEl = ref<HTMLElement | null>(null);
const modalHeaderEl = ref<HTMLElement | null>(null);
const playlistItems = ref<IPlaylistItem[]>([]);
const activePlaylistItem = ref<IPlaylistItem | null>(null);
const isEditingPlaylistItem = ref(false);
const telestrationTags = ref<IPixellotTag[]>([]);
const isEvent = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IEvent => vid?.type === VIDEO_TYPE.EVENT;
const isPlaylist = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IPlaylist => vid?.source_type === SOURCE_TYPE.PLAYLIST;
const isClip = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IClip => vid?.type === VIDEO_TYPE.CLIP;
const isHighlight = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IClip => vid?.type === VIDEO_TYPE.ATHLETE_HIGHLIGHT || vid?.type === VIDEO_TYPE.GAME_HIGHLIGHT || vid?.type === "autohighlight";
const controlLink = computed<IPlayerControlLink | undefined>(() => {
  const vid = unref(video);
  const isEventPage = route.path.startsWith(`/events/${vid?.eventId}`);

  if (isEvent(vid)) {
    if (playlist.value) {
      return {
        target: "_blank" as const,
        label: `${vid.game_info.team1_name} vs ${vid.game_info.team2_name}`,
        url: `/events/editor/${vid.eventId}`,
      };
    }

    return {
      target: "_self" as const,
      label: t("labels.open_in_editor"),
      url: `/events/editor/${vid.eventId}`,
    };
  }
  else if ((isClip(vid) || isHighlight(vid)) && !isEventPage) {
    return {
      target: "_self" as const,
      label: t("labels.go_to_video_page"),
      url: `/events/${vid.eventId}`,
    };
  }

  return undefined;
});
const { arrowup, arrowdown, escape } = useMagicKeys();
const playerReadyCallbacks = ref<((player: IPixellotPlayer) => void)[]>([]);
const cachedPiPPosition = useSessionStorage("player-pip-position", { x: 40, y: 40 });
const { x, y, isDragging } = useDraggable(modalRootEl, {
  preventDefault: true,
  stopPropagation: true,
  handle: modalHeaderEl,
  initialValue: cachedPiPPosition.value,
  onEnd: onDragEnd,
});
const { left, right } = useElementBounding(window.document.body);
const { width, height } = useElementBounding(modalRootEl, { immediate: false });
const restrictedX = computed(() => clamp(left.value, x.value, right.value - width.value));
const restrictedY = computed(() => clamp(0, y.value, window.innerHeight - height.value));

const upNextBannerDefaultSeconds = 5;
const prevNextItemIndexes = computed(() => {
  if (!playlistItems.value?.length) {
    return null;
  }
  const currentIndex = playlistItems.value?.findIndex(item => item.id === activePlaylistItem.value?.id);

  return {
    nextIndex: currentIndex + 1,
    previousIndex: currentIndex - 1,
    currentIndex,
  };
});
const nextPlaylistItem = computed(
  () => prevNextItemIndexes.value && playlistItems.value && playlistItems.value[prevNextItemIndexes.value?.nextIndex],
);
const isShowUpNextBanner = computed(() => {
  return (
    nextPlaylistItem.value
    && playerState.value?.currentTime
    && activePlaylistItem.value?.endTime
    && playerState.value?.currentTime >= activePlaylistItem.value?.endTime - upNextBannerDefaultSeconds
  );
});
const seekButtonsConfig = computed(() => {
  const prevIndex = prevNextItemIndexes.value?.previousIndex || 0;
  const nextIndex = prevNextItemIndexes.value?.nextIndex || 0;

  return {
    isActive: !!playlistItems.value.length,
    isPreviousDisabled: isEditingPlaylistItem.value || prevIndex < 0,
    isNextDisabled: isEditingPlaylistItem.value || nextIndex > playlistItems.value?.length - 1,
    onClickNext: () => {
      if (video.value) {
        segment.track("Moved to Next Tag", formatTrackPlaylistItem(playlistItems.value[nextIndex]));
      }

      onPlaylistItemClick(playlistItems.value[nextIndex]);
    },
    onClickPrevious: () => {
      if (video.value) {
        segment.track("Moved to Previous Tag", formatTrackPlaylistItem(playlistItems.value[prevIndex]));
      }

      onPlaylistItemClick(playlistItems.value[prevIndex]);
    },
  };
});

const currentVideoClipOptions = computed<IPixellotPlayerVideoClipOptions | undefined>(() => {
  if (isEditingPlaylistItem.value)
    return undefined;

  if (activePlaylistItem.value) {
    const options: IPixellotPlayerVideoClipOptions = {
      start: activePlaylistItem.value.startTime,
      end: activePlaylistItem.value.endTime,
      // Restart on last item?
      // restart_beginning: !nextPlaylistItem.value
    };
    return options;
  }
  return undefined;
});

async function open(vid: IPlaylist | IEvent | IBasicVideo | null) {
  if (!vid) {
    toast.error(t("labels.unknown_video"));
    close();
    return;
  }

  isOpen.value = true;
  if (isPlaylist(vid)) {
    const accessToken = route.query.accessToken as string;
    const items = await getPlaylistItems(vid.id, { accessToken, locale: locale.value }).catch((err) => {
      onError(err);
      return [];
    });
    playlistItems.value = items;
    playlist.value = { ...vid, size: vid.size || items.length };

    if (items.length === 0) {
      toast.error(t("errors.navigator_playlist_error"));
      close();
      return;
    }

    onPlaylistItemClick(items.find(item => activePlaylistItem.value?.id === item.id) || items[0]);
    togglePiPMode(false);

    // Omitting saving system playlist id into query since after reload the fetch will not work for those
    // TODO: Check with BE if we can make it work
    if (vid.type !== "system") router.replace({ query: { ...route.query, playlistId: vid.id } });
    return;
  }

  video.value = vid;

  // togglePiPMode(video.value && vid.id === video.value.id);
  togglePiPMode(false);
  router.replace({ query: { ...route.query, videoType: vid.type, videoId: vid.id } });
}

watch(isOpen, (value) => {
  if (value) {
    document.documentElement.classList.add("overflow-hidden");
  }
  else {
    document.documentElement.classList.remove("overflow-hidden");
  }
}, { immediate: true });

const toggleTelestrationMode = computed(() => canvasStore.telestrationModeEnabled);

watch(toggleTelestrationMode, () => {
  if (isOpen.value) loadVideoFromQuery();
});

function close() {
  if (canvasStore.telestrationModeEnabled) {
    canvasStore.telestrationModeEnabled = false;
    telestrationStore.exitTelestrationMode();
  }
  isOpen.value = false;
  video.value = null;
  togglePiPMode(false);
  isInNativePiPMode.value = false;
  router.replace({ query: { ...route.query, videoType: undefined, videoId: undefined, playlistId: undefined } });
  playlist.value = null;
  playlistItems.value = [];
  activePlaylistItem.value = null;
  isEditingPlaylistItem.value = false;
}

function onDragEnd(position: { x: number; y: number }) {
  cachedPiPPosition.value.x = position.x;
  cachedPiPPosition.value.y = position.y;
}

function onError(err: unknown) {
  toast.error(parseErrorMessage(err, { t }));
  close();
}

function updatePlaylistName(name?: string) {
  if (playlist.value && name) {
    playlist.value = { ...playlist.value, name };
  }
}

function setCurrentTime(time: number) {
  if (!videoPlayerRef.value?.player) {
    console.warn(
      "The current time of the video player cannot be set because the video player instance has not been initialized yet.",
    );
    return;
  }

  videoPlayerRef.value.player.currentTime(time);
}

function onPlaylistItemClick(playlistItem: IPlaylistItem | null) {
  if (!playlistItem) {
    return toast.error(t("errors.something_went_wrong"));
  }

  if (playlist.value) {
    setTelestrationData(playlistItem);
  }

  activePlaylistItem.value = playlistItem;
  video.value = playlistItem.playlist_source;
}

function setTelestrationData(playlistItem: IPlaylistItem) {
  telestrationStore.activePlaylistItem = playlistItem;
  telestrationStore.playlistId = playlist.value?.id || "";
  telestrationTags.value = (playlistItem.telestrations || []).map(({ start }) => ({
    time: (start || 0) - playlistItem.startTime,
    representation: TagRepresentation.Telestration,
  }));
}

function removeItemsFromPlaylist(items: IPlaylistItem[]) {
  const idsSet = new Set(items.map(item => item.id));
  const isActiveItemDeleted = idsSet.has(activePlaylistItem.value?.id || "");
  // remove deleted playlist item/s from main list to update seek buttons config and autoplay
  playlistItems.value = playlistItems.value.filter(item => !idsSet.has(item.id));

  if (!playlistItems.value?.length) {
    // close playlist when last item was deleted
    close();

    return;
  }
  if (isActiveItemDeleted) {
    // change source if deleted tag was active
    onPlaylistItemClick(playlistItems.value[0]);
  }
}

function onUpdatePlaylistItem(updatedItem: IPlaylistItem) {
  playlistItems.value = playlistItems.value.map(item => (item.id === updatedItem?.id ? { ...updatedItem } : item));
}

function onPlayerLoaded(data: IPlayerData) {
  // Seek to the shared time
  if (route.query.time) setCurrentTime(Number(route.query.time));

  // Execute pending player ready callbacks
  while (playerReadyCallbacks.value.length) {
    const cb = playerReadyCallbacks.value.shift();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    cb && cb(data.player);
  }
}

function loadVideoFromQuery() {
  const accessToken = route.query.accessToken as string;

  if (route.query.videoId && route.query.videoType) {
    return getVideo(route.query.videoId as string, route.query.videoType as IBasicVideo["type"], { accessToken })
      .then(vid => open(vid))
      .catch(onError);
  }

  if (route.query.playlistId) {
    return getUserPlaylistById(route.query.playlistId as string, { accessToken, locale: locale.value })
      .then(playlist => open(playlist))
      .catch(onError);
  }

  return Promise.resolve();
}

function onVideoEnded() {
  nextVideo();
}

function onTagClick(tag: { event: PointerEvent | MouseEvent; data: IPixellotTag | IPixellotTagGroup }) {
  if (tag.data.representation === TagRepresentation.Telestration && activePlaylistItem.value) {
    videoPlayerRef.value?.player?.pause();
    canvasStore.telestrationModeEnabled = true;
    // useTelestrationStore().enterTelestrationMode(activePlaylistItem.value);
  }
}

onMounted(loadVideoFromQuery);

/**
 * FIXME:
 * Sometimes there might be false "ended" events when playing the playlist items
 * e.g. the playlist item didn't loaded yet but the ended event was fired
 * this was triggering next item to be played without first even to start
 *
 * hence this solution with rebounding event listener introduced..
 * We watch for the activePlaylistItem _and_ playerInstance changes because on first load the playerInstance is not ready yet,
 * so the event listener is not attached. If we wait for the playerInstance to be ready too that means we won't miss adding the `ended` event.
 * it's a bit clunky, but at least does the job partially - we need to solve it better in future
 */
watch([activePlaylistItem, playerInstance], () => {
  if (activePlaylistItem.value && playerInstance.value) {
    playerInstance.value.off("ended", onVideoEnded);
    playerInstance.value.one("loadedmetadata", () => {
      setTimeout(() => {
        playerInstance.value?.one("ended", onVideoEnded);
      });
    });
  }
});

watch(
  () => isInPiPMode.value || isInNativePiPMode.value,
  (isInPiPMode) => {
    if (!video.value) return;

    if (isInPiPMode) {
      segment.track("Match Editor PiP Mode Opened", formatTrackVideo(video.value));
    }
    else {
      segment.track("Match Editor PiP Mode Closed", formatTrackVideo(video.value));
    }
  },
);

watch(
  () => playerInstance.value?.player_,
  (player: IPixellotPlayer | undefined) => {
    if (!player) return;

    player.on("enterpictureinpicture", () => (isInNativePiPMode.value = true));
    player.on("leavepictureinpicture", () => (isInNativePiPMode.value = false));
  },
);

function previousVideo() {
  if (seekButtonsConfig.value.isActive && !seekButtonsConfig.value.isPreviousDisabled) {
    return seekButtonsConfig.value.onClickPrevious();
  }

  console.warn("Previous video is not available");
}
function nextVideo() {
  if (seekButtonsConfig.value.isActive && !seekButtonsConfig.value.isNextDisabled) {
    return seekButtonsConfig.value.onClickNext();
  }

  console.warn("Next video is not available");
}

if (escape) {
  whenever(escape, close);
}

watch(arrowup, (value) => {
  if (value && !canvasStore.telestrationModeEnabled) {
    previousVideo();
  }
});

watch(arrowdown, (value) => {
  if (value && !canvasStore.telestrationModeEnabled) {
    nextVideo();
  }
});

provideFloatingPlayer({ open, close, video, player: playerInstance, playerState, togglePiPMode });
</script>

<template>
  <ClientOnly>
    <Teleport to="body">
      <!-- https://stackoverflow.com/questions/68998731/vue-transition-with-tailwind -->
      <Transition
        enter-from-class="opacity-0"
        leave-to-class="opacity-0"
        enter-active-class="transition-opacity duration-500"
        leave-active-class="transition-opacity duration-500"
      >
        <!-- BACKGROUND -->
        <div
          v-if="isOpen"
          ref="modalRootEl"
          class="fixed z-30"
          :class="[
            isInPiPMode
              ? 'size-max touch-none select-none'
              : 'left-0 top-0 grid h-screen w-screen place-items-center overflow-auto bg-black/40 py-20',
          ]"
          :style="isInPiPMode ? { top: `${restrictedY}px`, left: `${restrictedX}px` } : ''"
          @click.self="isInPiPMode ? null : close()"
        >
          <!-- POPUP -->
          <div
            class="rounded bg-white shadow-lg dark:bg-neutral-dark-700"
            :class="[isInPiPMode ? 'flex w-[calc(100vw-32px)] flex-col-reverse lg:w-[500px]' : playlist ? 'w-11/12 max-w-[1320px]' : 'w-11/12 lg:w-8/12']"
          >
            <!-- HEADER -->
            <VideoPlayerFloatingHeader
              v-if="isInPiPMode ? true : !playlist"
              ref="modalHeaderEl"
              :video="video"
              :playlist="playlist"
              :is-in-pi-p-mode="isInPiPMode"
              :is-in-editing-mode="isEditingPlaylistItem"
              :active-playlist-item="activePlaylistItem"
              :player-error="playerError"
              :player-state="playerState"
              :close="close"
              :toggle-pi-p-mode="togglePiPMode"
              :edit-playlist="updatePlaylistName"
            />

            <!-- BODY -->
            <div :class="(playlist && !isInPiPMode) ? 'flex max-lg:flex-wrap' : ''">
              <div
                class="relative flex flex-col"
                :class="(playlist && !isInPiPMode) ? 'lg:basis-2/3 basis-full' : ''"
              >
                <LazyVideoPlayer
                  v-if="video"
                  ref="videoPlayerRef"
                  class="relative w-full"
                  :tags="telestrationTags"
                  :video="video"
                  :video-clip="currentVideoClipOptions"
                  :player-class="{ player__compact: isInPiPMode }"
                  :control-link="controlLink"
                  :seek-buttons="seekButtonsConfig"
                  :up-next-video="
                    !isEditingPlaylistItem && playlist && nextPlaylistItem && nextPlaylistItem?.playlist_source
                  "
                  :up-next-video-shown="!!isShowUpNextBanner"
                  :up-next-video-category="playlist?.name"
                  :telestration-button-shown="!!playlist"
                  @mounted="onPlayerLoaded"
                  @up-next-click="onPlaylistItemClick(nextPlaylistItem)"
                  @tag-click="onTagClick($event)"
                >
                  <template #default="{ player, source, state }">
                    <LazyVideoPlayerPiPOverlay
                      v-if="!isDragging"
                      :pip-active="isInPiPMode"
                      :player="player"
                      :source="source"
                      :state="state"
                      @close="close()"
                      @exit-pip="togglePiPMode(false)"
                    />
                  </template>
                </LazyVideoPlayer>
                <div
                  v-else
                  class="aspect-video"
                >
                  <RLoadingOverlay />
                </div>
                <div v-if="canvasStore.telestrationModeEnabled" class="absolute bottom-0 left-0">
                  <TCanvasContainer />
                </div>
                <div id="floating-player-footer" />
              </div>
              <div
                v-if="!canvasStore.telestrationModeEnabled && playlist && playlistItems.length > 0 && !isInPiPMode"
                class="basis-full lg:basis-1/3"
                :class="[isEditingPlaylistItem ? 'hidden lg:block' : '']"
                :style="greaterOrEqualLg ? { maxHeight: `${playerState?.height}px` } : null"
              >
                <VideoPlayerFloatingHeader
                  v-if="!isInPiPMode"
                  :video="video"
                  :playlist="playlist"
                  :is-in-editing-mode="isEditingPlaylistItem"
                  :active-playlist-item="activePlaylistItem"
                  :is-in-pi-p-mode="isInPiPMode"
                  :player-error="playerError"
                  :player-state="playerState"
                  :close="close"
                  :toggle-pi-p-mode="togglePiPMode"
                  :edit-playlist="updatePlaylistName"
                />
                <UDivider lighter />
                <PlaylistsList
                  v-if="!canvasStore.telestrationModeEnabled"
                  :playlist="playlist"
                  :playlist-items="playlistItems"
                  :player="playerInstance"
                  :active-video="video"
                  :player-state="playerState"
                  :active-item="activePlaylistItem"
                  @toggle-edit-mode="isEditingPlaylistItem = !isEditingPlaylistItem"
                  @playlist-item-click="onPlaylistItemClick"
                  @update-playlist-item="onUpdatePlaylistItem($event)"
                  @delete-playlist-items="removeItemsFromPlaylist($event)"
                  @close="close()"
                />
              </div>
              <TelestrationSidePanel
                v-if="canvasStore.telestrationModeEnabled"
                :style="greaterOrEqualLg ? { maxHeight: `${playerState?.height}px` } : null"
                class="basis-full lg:basis-1/3"
              />
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </ClientOnly>
</template>

<style>
.player__compact .vjs-control-bar > *:not(.vjs-progress-control) {
  display: none;
}

.player__compact .vjs-control-bar .vjs-progress-control {
  bottom: 24px;
  z-index: 20;
}

.vjs-control-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
